import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

function AdminDashboard() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  
useEffect(() => {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    navigate("/");
    return;
  }

  try {
    const decodedToken = jwtDecode(accessToken);
    const { role } = decodedToken;
    if (role === "user") {
      navigate("/user/dashboard");
    } else if (role === "admin") {
      console.log(" ");
    } else {
      navigate("/");
    }
  } catch (error) {
    console.error("Invalid token specified:", error);
    navigate("/");
  }
}, [navigate]);

useEffect(() => {
  fetchUsers();
}, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/users`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error.message);
      setError("An error occurred while fetching users");
    }
  };

  const toggleStatus = async (userId, status) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/updateStatus/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ status }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update user status");
      }
      fetchUsers(); // Refresh the user list after updating status
    } catch (error) {
      console.error("Error updating user status:", error.message);
      setError("An error occurred while updating user status");
    }
  };

  const handleCreateUser = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/createUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, email, password, role }),
        }
      );

      if (!response.ok) {
        const { error } = await response.json();
        setError(error);
        return;
      }

      setUsername("");
      setEmail("");
      setPassword("");
      setRole("");

      fetchUsers(); // Refresh the user list after creating a new user
    } catch (error) {
      console.error("Error creating user:", error.message);
      setError("An error occurred while creating user");
    }
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setShowModal(false);
    setNewPassword("");
  };

  const handleChangePassword = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: selectedUser.user_id, newPassword }),
        }
      );

      if (!response.ok) {
        alert(error);
        const { error } = await response.json();
        setError(error);
        return;
      }
      alert("password changed");

      closeModal(); // Close the modal after changing the password
    } catch (error) {
      console.error("Error changing password:", error.message);
      setError("An error occurred while changing the password");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    navigate("/");
  };


  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center">Admin Dashboard</h2>
      <button className="btn btn-secondary mb-3" onClick={handleLogout}>
        Logout
      </button>

      <div className="row mb-4">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              type="text"
              id="username"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="role" className="form-label">
              Role
            </label>
            <select
              id="role"
              className="form-select"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <option value="">Select Role</option>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <button className="btn btn-primary" onClick={handleCreateUser}>
            Create User
          </button>
        </div>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`statusSwitch${user.user_id}`}
                      checked={user.status === "active"}
                      onChange={() =>
                        toggleStatus(
                          user.user_id,
                          user.status === "active" ? "inactive" : "active"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`statusSwitch${user.user_id}`}
                    >
                      {user.status}
                    </label>
                  </div>
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => openModal(user)}
                  >
                    Change Password
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Change Password Modal */}
      {showModal && (
        <div className="modal show d-block" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Change Password for {selectedUser.username}
                </h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input
                  type="password"
                  className="form-control"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleChangePassword}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
