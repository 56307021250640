import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Import jwtDecode correctly

function UserDashboard() {
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [uploads, setUploads] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      navigate("/");
      return;
    }
    try {
      const decodedToken = jwtDecode(accessToken);
      const { role } = decodedToken;
      if (role === "user") {
        console.log("");
      } else if (role === "admin") {
        navigate("/admin/dashboard");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Invalid token specified:", error);
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    fetchUploads();
  }, []);

  const fetchUploads = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const decodedToken = jwtDecode(accessToken);
    const { userId } = decodedToken;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/${userId}/uploads`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const { error } = await response.json();
        setError(error);
        return;
      }

      const data = await response.json();
      setUploads(data);
    } catch (error) {
      console.error("Error fetching uploads:", error.message);
      setError("An error occurred while fetching the uploads");
    }
  };

  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    const maxSize = fileType === "image" ? 3 * 1024 * 1024 : 10 * 1024 * 1024; // 3MB for images, 10MB for videos

    if (file.size > maxSize) {
      setError(
        `File size should not exceed ${fileType === "image" ? "3MB" : "10MB"}.`
      );
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if (fileType === "image") {
        setImageFile(reader.result); // Set the base64 encoded string for image
      } else if (fileType === "video") {
        setVideoFile(reader.result); // Set the base64 encoded string for video
      }
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = async (file, fileType) => {
    if (!file) {
      setError(`Please select a ${fileType} to upload`);
      return;
    }

    const accessToken = localStorage.getItem("accessToken");
    const username = localStorage.getItem("username");
    const decodedToken = jwtDecode(accessToken);
    const { userId } = decodedToken;

    if (!accessToken || !username) {
      setError("Access token or username is missing");
      return;
    }

    try {
      const endpoint = fileType === "image" ? "uploadImage" : "uploadVideo"; // Adjust the endpoint based on fileType
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/${endpoint}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ fileData: file, username, userId }),
        }
      );

      if (!response.ok) {
        const { error } = await response.json();
        setError(error);
        return;
      }

      const data = await response.json();
      console.log(`${fileType} uploaded successfully:`, data);

      if (fileType === "image") {
        setImageFile(null);
      } else if (fileType === "video") {
        setVideoFile(null);
      }
      setError("");
      setSuccess(`${fileType} uploaded successfully!`);

      // Fetch uploads again to update the list
      fetchUploads();
    } catch (error) {
      console.error(`Error uploading ${fileType}:`, error.message);
      setError(`An error occurred while uploading the ${fileType}`);
    }
  };

  const removeUpload = async (upload) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/removeImage`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ uploadId: upload.brochure_id }),
        }
      );

      if (!response.ok) {
        const { error } = await response.json();
        setError(error);
        return;
      }

      setSuccess("Upload removed successfully!");
      // Fetch uploads again to update the list
      fetchUploads();
    } catch (error) {
      console.error("Error removing upload:", error.message);
      setError("An error occurred while removing the upload");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    navigate("/");
  };


  return (
    <div className="container mt-4">
      <h2 className="mb-4">Welcome to User Dashboard</h2>
      <button className="btn btn-secondary mb-3" onClick={handleLogout}>
        Logout
      </button>

      <div className="mb-3">
        <label htmlFor="imageFile" className="form-label">
          Select Image File:
        </label>
        <input
          type="file"
          id="imageFile"
          className="form-control"
          accept="image/*"
          onChange={(event) => handleFileChange(event, "image")}
        />
      </div>
      <button
        className="btn btn-primary mb-3"
        onClick={() => handleUpload(imageFile, "image")}
      >
        Upload Image
      </button>

      <div className="mb-3">
        <label htmlFor="videoFile" className="form-label">
          Select Video File:
        </label>
        <input
          type="file"
          id="videoFile"
          className="form-control"
          accept="video/*"
          onChange={(event) => handleFileChange(event, "video")}
        />
      </div>
      <button
        className="btn btn-primary mb-3"
        onClick={() => handleUpload(videoFile, "video")}
      >
        Upload Video
      </button>

      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}

      <h3 className="mb-4">Your Uploads</h3>
      <div className="row">
        {uploads.map((upload) => (
          <div key={upload.brochure_id} className="col-md-4 mb-4">
            <div className="card">
              {upload.file_path.endsWith(".mp4") ? ( // Adjust this check for video files
                <video className="card-img-top" controls>
                  <source
                    src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${upload.username}/${upload.file_path}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${upload.username}/${upload.file_path}`}
                  className="card-img-top"
                  alt="Upload"
                />
              )}
              <div className="card-body">
                <p className="card-text">
                  Uploaded at: {new Date(upload.uploaded_at).toLocaleString()}
                </p>
                <button
                  className="btn btn-danger"
                  onClick={() => removeUpload(upload)}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserDashboard;
