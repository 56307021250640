import React, { useState, useEffect, useRef } from "react";
import HTMLFlipBook from "react-pageflip";
import { useParams } from "react-router-dom";
import "./flip.css";

function UserMediaPage() {
  const [mediaItems, setMediaItems] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const { username } = useParams();
  const flipBook = useRef(null);
  const audioRef = useRef(null);
  const videoRefs = useRef([]);
  const [showGoToFirstPageButton, setShowGoToFirstPageButton] =
      useState(false);


  useEffect(() => {
    fetchMediaItems();
  }, [username]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (flipBook.current && flipBook.current.pageFlip()) {
        const pageCount = flipBook.current.pageFlip().getPageCount();
        if (pageIndex < pageCount - 1) {
          console.log(pageIndex, pageCount-1)
          flipBook.current.pageFlip().flipNext();
        } else {
          console.log("this is end page");
          // flipBook.current.pageFlip().flip(0);
          setShowGoToFirstPageButton(true);
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [pageIndex]);

  useEffect(() => {
    document.addEventListener("click", handlePlayAudio);
    return () => {
      document.removeEventListener("click", handlePlayAudio);
    };
  }, []);

 const fetchMediaItems = async () => {
   try {
     const response = await fetch(
       `${process.env.REACT_APP_API_BASE_URL}/media/${username}/`
     );
     if (!response.ok) {
       throw new Error("Failed to fetch media items");
     }
     const data = await response.json();

     // Determine the type of each media item based on the file extension
     let mediaItemsWithTypes = data.map((item, index) => {
       const extension = item.url.split(".").pop();
       const type = ["jpg", "jpeg", "png", "gif"].includes(
         extension.toLowerCase()
       )
         ? "image"
         : "video";
       return { ...item, type, ref: React.createRef() };
     });

     // Check if video type exists, if yes, move it to the end of the array
     const videoIndex = mediaItemsWithTypes.findIndex(
       (item) => item.type === "video"
     );
     if (videoIndex !== -1) {
       const videoItem = mediaItemsWithTypes.splice(videoIndex, 1)[0];
       mediaItemsWithTypes.push(videoItem);
     }

     console.log(mediaItemsWithTypes);
     setMediaItems(mediaItemsWithTypes);
   } catch (error) {
     console.error("Error fetching media items:", error.message);
   }
 };


  const onPageFlip = (e) => {
    setPageIndex(e.data);

    // Pause all videos
    videoRefs.current.forEach(videoRef => {
      if (videoRef && videoRef.current) {
        videoRef.current.pause();
      }
    });

    // Play the current video if it exists
    const currentMedia = mediaItems[e.data];
    if (currentMedia && currentMedia.type === 'video') {
      const currentVideoRef = videoRefs.current[e.data];
      if (currentVideoRef && currentVideoRef.current) {
        currentVideoRef.current.play();
      }
    }
  };

  const handlePlayAudio = () => {
     if (audioRef.current) {
       audioRef.current.volume = 0.5; // Set volume to 50%
       audioRef.current.play();
     }

    if (audioRef.current) {
      audioRef.current.play();
    }
    document.removeEventListener("click", handlePlayAudio); // Remove event listener after playing
  };

  const handleGoToFirstPage = () => {
    if (flipBook.current && flipBook.current.pageFlip()) {
      flipBook.current.pageFlip().turnToPage(0);
      setShowGoToFirstPageButton(false);
    }
  };

  return (
    <div className="userpage">
      <div className="flipbook-wrapper">
        <audio ref={audioRef} autoPlay loop>
          <source src="music.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <HTMLFlipBook
          ref={flipBook}
          width={400}
          height={600}
          maxShadowOpacity={0.5}
          mobileScrollSupport={false}
          autoSize={true}
          onFlip={onPageFlip}
        >
          {mediaItems.map((item, index) => (
            <div key={index} className="demoPage">
              {item.type === "image" ? (
                <img
                  src={item.url}
                  alt={`Image ${index + 1}`}
                  style={{ width: "100%", height: "100%" }}
                />
              ) : (
                <video
                  src={item.url}
                  controls
                  autoPlay
                  ref={(videoRefs.current[index] = React.createRef())}
                  style={{ width: "100%", height: "100%" }}
                >
                  Your browser does not support the video tag.
                </video>
              )}
              <div className="views-count">
                <i className="fas fa-eye"></i> {item.views}
              </div>
            </div>
          ))}
        </HTMLFlipBook>
        <div className="page-info">
          Page {pageIndex + 1} of {mediaItems.length}
        </div>
        {showGoToFirstPageButton && (
          <button
            className="go-to-first-page-btn"
            onClick={handleGoToFirstPage}
          >
            Go to First Page
          </button>
        )}
      </div>
    </div>
  );
}

export default UserMediaPage;
